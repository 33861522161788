



























import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Common from "../common/Common";

@Component({
  components: {
    ClipLoader
  }
})
export default class Login extends Vue {
  isLoading = true;
  errText = "";

  mounted() {
    if (!Common.useWebRTC()) {
      this.$router.push("/unsupport");
      return;
    }
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("category");
    firebase.auth().signOut();
    if (
      this.$route.query.from == "beauty" ||
      this.$route.query.from == "shinjuku"
    ) {
      firebase
        .auth()
        .signInWithCustomToken(this.$route.query.token as string)
        .then(userCredential => {
          // Signed in
          var user = userCredential.user;
          console.log(user);
          Common.getUserInfo().then(userInfo => {
            if (userInfo) {
              firebase
                .firestore()
                .collection("users")
                .doc(user!.uid)
                .update({ from: this.$route.query.from })
                .then(async () => {
                  if (this.$route.query.redirect) {
                    this.$router.push(this.$route.query.redirect as string);
                    return true;
                  }
                  if (this.$route.query.rid) {
                    this.openRoom(user, this.$route.query.rid as string);
                  } else {
                    this.$router.push("/chatlist");
                  }
                })
                .catch((error: any) => {
                  console.error(error);
                });
            }
            const savingUserInfo: any = {
              name: "お客様",
              from: this.$route.query.from,
              sex: "",
              selfIntro: "",
              fileExist: false,
              detail: "",
              date: "",
              web: "",
              role: "patient",
              prefecture: "",
              incompatibleMessage: "",
              sutoMessage: "",
              gid: "",
              group: {},
              createTime: firebase.firestore.FieldValue.serverTimestamp()
            };
            firebase
              .firestore()
              .collection("users")
              .doc(user!.uid)
              .set(savingUserInfo)
              .then(async () => {
                sessionStorage.removeItem("user");
                if (this.$route.query.rid) {
                  this.openRoom(user, this.$route.query.rid as string);
                } else {
                  this.$router.push("/chatlist");
                }
              })
              .catch((error: any) => {
                console.error(error);
              });
            return true;
          });
        })
        .catch(error => {
          console.log(error);
          var errorCode = error.code;
          var errorMessage = error.message;
          // ...
        });
    } else {
      var uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: (
            currentUser: any,
            credential: any,
            redirectUrl: any
          ) => {
            Common.getUserInfo().then(userInfo => {
              if (userInfo) {
                if (this.$route.query.redirect) {
                  this.$router.push(this.$route.query.redirect as string);
                  return true;
                }
                this.$router.push("/chatlist");
                return true;
              }
              if (this.$router.currentRoute.path === "/provider/login") {
                this.$router.push({
                  path: "/provider-setting",
                  query: { from: "login" }
                });
                return true;
              }
              // 動作確認のためLINEミニアプリ経由でもprovider-settingに飛ばす
              this.$router.push("/provider-setting");
              return true;
            });
          },
          signInFailure: (error: any) => {
            this.errText = "ログインに失敗しました";
            this.isLoading = false;
          },
          uiShown: () => {
            this.setLoadingBoxHidden();
          }
        },
        signInOptions: [
          {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            disableSignUp: { status: true }
          }
        ]
      };

      var ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(firebase.auth());
      ui.start("#firebaseui-auth-container", <any>uiConfig);
    }
  }

  openRoom(user: any, rid: string) {
    const ref = firebase
      .firestore()
      .collection("onayamis")
      .doc(`${rid}`);
    ref.get().then(onayami => {
      if (onayami.exists) {
        let obj = onayami.data()!;
        const startMessage = `【${obj.title}】\n担当者にお繋ぎしますので、しばらくお待ちください。`;
        ref
          .collection("rooms")
          .add({
            creator: user!.uid,
            clinic: obj.uid,
            patient: user!.uid,
            uids: [user!.uid, obj.uid],
            gids: [obj.gid],
            chatNum: 0,
            chatText: startMessage,
            chatUid: "admin",
            chatStart: true,
            clinicChatNum: 1,
            studentChatNum: 0,
            chatCreateTime: firebase.firestore.FieldValue.serverTimestamp(),
            status: "before",
            hasReviewed: false
          })
          .then(value => {
            const chatId = value.id;
            ref
              .collection("rooms")
              .doc(chatId)
              .collection("chats")
              .add({
                text: startMessage,
                createTime: firebase.firestore.FieldValue.serverTimestamp(),
                uid: "admin",
                partnerUid: "",
                type: "admin_message",
                read: true,
                push: false
              })
              .then(() => {
                this.$router.push(`/onayamis/${rid}/chat/${chatId}`);
              });
          });
      }
    });
  }
  setLoadingBoxHidden() {
    setTimeout(() => {
      if (
        document.getElementsByClassName("firebaseui-card-content").length != 0
      ) {
        this.isLoading = false;
      } else {
        this.setLoadingBoxHidden();
      }
    }, 300);
  }
}
